import React from "react";

export const Projects = () => {
  return (
    <div className="projects">
      <h1>projects</h1>
    </div>
  );
};

export const ProjectsOne = () => {
  return (
    <div className="projects">
      <h1>projects/projects1</h1>
    </div>
  );
};

export const ProjectsTwo = () => {
  return (
    <div className="projects">
      <h1>projects/projects2</h1>
    </div>
  );
};

export const ProjectsThree = () => {
  return (
    <div className="projects">
      <h1>projects/projects3</h1>
    </div>
  );
};
